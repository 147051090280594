import { message } from "antd";
import { useState, useEffect } from "react";
import axios from "axios";
import YouTube from "react-youtube";

import ServiceContent from "./assets/service-content.jpg";

const PROXY_URL = process.env.REACT_APP_SERVER_URL + '/private/property/official_site/proxy';
const API_KEY = process.env.REACT_APP_API_KEY;
const ADMIN_TOKEN = process.env.REACT_APP_ADMIN_TOKEN;



const GROUPS = {
  MAIN: 'main',
  HEADER: 'header',
};

const MAIN_ITEMS = {
  BANNER: 'banner',
  MOBILE: 'mobile_content',
  VIDEO: 'video_content',
};

const callApi = async (
  url: string,
  method: string = 'GET',
  body: Record<string, any> = {},
  query: Record<string, any> = {},
  isFileUpload: boolean = false
) => {
  try {
    const headers: Record<string, string | undefined> = {
      'x-api-key': API_KEY,
      'Authorization': `Bearer ${ADMIN_TOKEN}`
    };

    if (!isFileUpload) {
      headers['Content-Type'] = 'application/json';
    }

    const response = await axios.post(PROXY_URL, {
      url,
      method,
      body,
      query
    }, {
      headers
    });
    return response.data;
  } catch (error) {
    console.error('API 请求失败:', error);
    throw error;
  }
};

const getYoutubeVideoId = (url: string) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);
  return (match && match[2].length === 11) ? match[2] : null;
};

export default function Home(): JSX.Element {
  const [bannerImages, setBannerImages] = useState<string[]>([]);
  const [mobileContentImages, setMobileContentImages] = useState<string[]>([]);
  const [videoUrl, setVideoUrl] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");

  useEffect(() => {
    const initializeData = async () => {
      try {
        const groupsResponse = await callApi('/group/', 'GET', {}, {});
        const mainGroup = groupsResponse.find((group: any) => group.name === GROUPS.MAIN);
        const headerGroup = groupsResponse.find((group: any) => group.name === GROUPS.HEADER);

        if (headerGroup) {
          const groupDetail = await callApi(`/group/${headerGroup.id}`, 'GET', {}, {});
          if (groupDetail.items && groupDetail.items.length > 0) {
            const companyInfoItem = groupDetail.items
              .filter((item: { name: string; created_at: string }) => item.name === 'company_info')
              .sort((a: { created_at: string }, b: { created_at: string }) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())[0];

            if (companyInfoItem && companyInfoItem.information?.companyName) {
              setCompanyName(companyInfoItem.information.companyName);
            }
          }
        } else {
          message.error('can not find header group');
        }

        if (mainGroup) {
          const groupDetail = await callApi(`/group/${mainGroup.id}`, 'GET', {}, {});

          if (groupDetail.items && groupDetail.items.length > 0) {
            const itemsByType: Record<string, any> = {};
            groupDetail.items.forEach((item: { name: string; created_at: string }) => {
              if (!itemsByType[item.name] ||
                new Date(item.created_at) > new Date(itemsByType[item.name].created_at)) {
                itemsByType[item.name] = item;
              }
            });

            if (itemsByType[MAIN_ITEMS.BANNER]) {
              const bannerUrls = itemsByType[MAIN_ITEMS.BANNER].blobs?.map((blob: { url: string }) => blob.url) || [];
              setBannerImages(bannerUrls);
            }

            if (itemsByType[MAIN_ITEMS.MOBILE]) {
              const mobileUrls = itemsByType[MAIN_ITEMS.MOBILE].blobs?.map((blob: { url: string }) => blob.url) || [];
              setMobileContentImages(mobileUrls);
            }

            if (itemsByType[MAIN_ITEMS.VIDEO] && itemsByType[MAIN_ITEMS.VIDEO].information?.videoUrl) {
              setVideoUrl(itemsByType[MAIN_ITEMS.VIDEO].information.videoUrl);
            }
          }
        } else {
          message.error('can not find main group');
        }
      } catch (error) {
        console.error('initialize failed:', error);
        message.error('initialize failed');
      }
    };

    initializeData();
  }, []);

  return (
    <>
      {mobileContentImages.length > 0 && (
        mobileContentImages.map((url, index) => (
          <img
            key={index}
            src={url}
            alt={`mobile content ${index + 1}`}
            className="w-full h-full object-cover mt-[58px]"
            width={1920}
            height={1080}
          />
        ))
      )}

      <button className="mt-[58px]" onClick={() => {
        window.open('/combo', '_blank');
      }}>
        <img src={ServiceContent} alt="combo.png"/>
      </button>
      
      {videoUrl && getYoutubeVideoId(videoUrl) && (
        <div className="relative w-full h-[400px]">
          <YouTube
            videoId={getYoutubeVideoId(videoUrl) || ''}
            opts={{
              width: '100%',
              height: '100%',
              playerVars: {
                autoplay: 0,
              },
            }}
            className="absolute top-0 left-0 w-full h-full"
          />
        </div>  
      )}
    </>
  );
}
