import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./Home";
import Recruit from "./Recruit";
import Combo from "./Combo";
import Layout from "./components/Layout";

const App: React.FC = () => {
  const [companyName, setCompanyName] = React.useState("慰心科技");

  return (
    <Layout companyName={companyName}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/recruit" element={<Recruit />} />
        <Route path="/combo" element={<Combo />} />
      </Routes>
    </Layout>
  );
};

export default App;
