import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { HomeOutlined } from '@ant-design/icons';
import ServicePlan from './components/ServicePlan';
import Tab1 from './assets/溫情加值服務.png';
import { Form, Input, DatePicker, Radio, Select, Upload, Button, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import callApi from './utils/callApi';
import Notice from './assets/溫馨提醒.jpg';

const Combo: React.FC = () => {
  
  const navigate = useNavigate();
  const [form] = Form.useForm();

  // breadcrumb navigation
  const BreadcrumbNav = () => (
    <div className="flex flex-col space-y-2 mb-4">
      <div className="flex items-center text-sm">
        <button className='text-green-600' onClick={() => navigate('/')}>
          <HomeOutlined className="mr-1" />
        </button>
        <span className="mx-2 text-gray-500">/</span>
        <span className="text-gray-500">服務方案</span>
      </div>
    </div>
  );
  // useEffect(() => {
  //   const fetchData = async () => {
  //     const response = await callApi.get('/private/property/ecommerce/combo');
  //     console.log('response', response);
  //   };
  //   fetchData();
  // }, []);

  const onFinish = async (values: any) => {
    try {
      // 這裡添加您的 API 調用
      // await callApi.post('/your-endpoint', values);
      message.success('表單提交成功！');
      
      // 清空所有表單欄位
      form.resetFields();
      
      // 清空文件上傳
      const uploadFields = ['親友圖片', '親友聲音'];
      uploadFields.forEach(field => {
        form.setFieldValue(field, []);
      });
      
    } catch (error) {
      message.error('提交失敗，請稍後重試');
    }
  };

  return (
    <>
      <div className='flex flex-col gap-4 p-4'>
        <BreadcrumbNav />
        <ServicePlan />
        <img src={Tab1} alt="溫情加值" className="w-full" />
      </div>
    <div className="flex flex-col gap-4  mx-auto bg-[#F6F4EF] p-4 mb-4 pt-[30px] relative">
      
      <Form 
        layout="vertical"
        form={form}
        onFinish={onFinish}
      >
        {/* 基本資訊 */}
        <div className="text-xl font-semibold tracking-[0.2em] decoration-from-font no-decoration-skip-ink font-inter leading-5 mb-6 text-center">
          形象建立資訊填寫
        </div>
        <div className="flex flex-col gap-4 bg-white p-4 rounded-lg mb-4">
          <h2 className='text-lg font-bold'>基本資訊</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <Form.Item label="親友姓名" name="name">
              <Input placeholder="請輸入服務名稱" />
            </Form.Item>

            <Form.Item label="親友出生日期" name="birthDate">
              <DatePicker placeholder="請選擇日期" style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item label="親友性別" name="gender">
              <Radio.Group>
                <Radio value="male">男</Radio>
                <Radio value="female">女</Radio>
              </Radio.Group>
            </Form.Item>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <Form.Item label="家屬聯絡電話" name="phone">
              <Input placeholder="請輸入電話號碼" />
            </Form.Item>

            <Form.Item label="出殯日期" name="funeralDate">
              <DatePicker placeholder="請選擇日期" style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item label="禮儀業者" name="guarantor" className='col-span-3 w-1/3'>
              <Select placeholder="佰天人本">
                <Select.Option value="佰天人本">恆天人本</Select.Option>
              </Select>
            </Form.Item>
          </div>
        </div>

        {/* 祭禮 */}
        <div className="flex flex-col gap-4 bg-white p-4 rounded-lg mb-4">
          <h2 className='text-lg font-bold'>祭禮</h2>
          
          <div className="flex flex-col gap-6">
            {/* 公祭 */}
            <div className="flex flex-col gap-2">
              <h3>公祭</h3>
              <div className="text-gray-500 text-sm">台詞</div>
              <Form.Item name="publicCeremony">
                <Input.TextArea 
                  rows={4} 
                  placeholder="請輸入台詞"
                />
              </Form.Item>
            </div>

            {/* 家祭 */}
            <div className="flex flex-col gap-2">
              <h3>家祭</h3>
              <div className="text-gray-500 text-sm">台詞</div>
              <Form.Item name="familyCeremony">
                <Input.TextArea 
                  rows={4} 
                  placeholder="請輸入台詞"
                />
              </Form.Item>
            </div>

            {/* 遠行 */}
            <div className="flex flex-col gap-2">
              <h3>遠行</h3>
              <div className="text-gray-500 text-sm">台詞</div>
              <Form.Item name="farewell">
                <Input.TextArea 
                  rows={4} 
                  placeholder="請輸入台詞"
                />
              </Form.Item>
            </div>

            {/* 動作呈現 */}
            <div className="flex flex-col gap-2">
              <h3>動作呈現</h3>
              <Form.Item name="action">
                <Radio.Group>
                  <Radio value="sit">坐下</Radio>
                  <Radio value="stand">站起</Radio>
                  <Radio value="turn">轉身</Radio>
                  <Radio value="bow">揖手</Radio>
                  <Radio value="worship">敬禮</Radio>
                  <Radio value="other">其他（請填於備註欄）</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
          </div>
        </div>

        {/* 檔案上傳 */}
        <div className="flex flex-col gap-4 bg-white p-4 rounded-lg mb-4">
          <h2 className='text-lg font-bold'>檔案上傳</h2>

          {/* 親友圖片上傳 */}
          <div className="flex flex-col gap-2">
            <div className="space-y-2">
              <div className='block text-lg font-bold'>親友圖片</div>
              <div className="text-red-500 text-sm">※ 提醒您 圖片解析度需達1080P以上、需為單獨照片、需為正面照片</div>
            </div>
            
            <div className="flex gap-4">
              <Upload
                listType="picture-card"
                maxCount={3}
                multiple
              >
                <div className="flex flex-col items-center text-blue-600">
                  <UploadOutlined />
                  <div>上傳圖片</div>
                </div>
              </Upload>
            </div>
          </div>

          {/* 親友聲音上傳 */}
          <div className="flex flex-col gap-2">
            <div className="space-y-2">
              <div className='block text-lg font-bold'>親友聲音</div>
              <div className="text-red-500 text-sm">※ 提醒您 聲音檔案不可有太多雜訊、僅可以是1人在說話、音檔說話長度需超過30秒</div>
            </div>

            <div className="flex flex-col gap-2">
              {/* 上傳按鈕 */}
              <Upload className="audio-upload">
                <Button icon={<UploadOutlined />} className="border border-dashed text-blue-600" style={{ height: '40px' }}>
                  上傳音檔
                </Button>
              </Upload>
            </div>
          </div>
        </div>
        
        {/* 提交按鈕 */}
        <div className="flex justify-center mt-6">
          <Button 
            type="primary" 
            htmlType="submit" 
            className="bg-[#11386F] w-[282px] h-[42px] rounded-tl-[5px]"
          >
            提交
          </Button>
        </div>
      </Form>
      <img src={Notice} alt="溫馨提醒" className="w-full" />
    </div>
    </>
  );
};

export default Combo;
