import React from 'react';
import { Link } from 'react-router-dom';
import { Tabs } from 'antd';
import Tab1 from '../assets/溫情重聚.png';
import Tab2 from '../assets/AI永恆記憶.png';
const ServicePlan: React.FC = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      {/* 將原來的標題區塊替換成 Tabs */}
      <Tabs
        defaultActiveKey="1"
        centered
        items={[
          {
            key: '1',
            label: '溫情重聚',
            children: (
              <>
                <img src={Tab1} alt="方案" className="w-full" />
              </>
            ),
          },
          {
            key: '2',
            label: 'AI永恆記憶',
            children: (
                <>
                <img src={Tab2} alt="方案" className="w-full" />
              </>
            ),
          },
        ]}
      />
    </div>
  );
};

export default ServicePlan; 