import React from 'react';
import { Link } from 'react-router-dom';

const Recruit: React.FC = () => {
  return (
    <div className="container mx-auto px-4 pt-[70px] relative">
      <Link to="/" className="absolute top-4 left-1 bg-[#EAE6D9] text-black font-semibold py-2 px-4 rounded-lg hover:bg-[#D8D2C0] transition-colors duration-300">
        回首頁
      </Link>
      <img src="/recruit.png" alt="recruit" className="w-full" />
    </div>
  );
};

export default Recruit;
