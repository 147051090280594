import { Carousel, Tabs, message, Spin } from "antd";
import { useState, useEffect } from "react";
import axios from "axios";
import YouTube from "react-youtube";

interface Partner {
    id: number;
    category: string;
    logo: string;
    url: string;
}

interface LayoutProps {
    children: React.ReactNode;
    companyName: string;
}

const PROXY_URL = process.env.REACT_APP_SERVER_URL + '/private/property/official_site/proxy';
const API_KEY = process.env.REACT_APP_API_KEY;
const ADMIN_TOKEN = process.env.REACT_APP_ADMIN_TOKEN;

const GROUPS = {
    FOOTER: 'footer',
    MAIN: 'main',
    HEADER: 'header',
};

const MAIN_ITEMS = {
    PARTNERS: 'partners_info',
    BANNER: 'banner',
    MOBILE: 'mobile_content',
    VIDEO: 'video_content',
};

const callApi = async (
    url: string,
    method: string = 'GET',
    body: Record<string, any> = {},
    query: Record<string, any> = {},
    isFileUpload: boolean = false
) => {
    try {
        const headers: Record<string, string | undefined> = {
            'x-api-key': API_KEY,
            'Authorization': `Bearer ${ADMIN_TOKEN}`
        };

        if (!isFileUpload) {
            headers['Content-Type'] = 'application/json';
        }

        const response = await axios.post(PROXY_URL, {
            url,
            method,
            body,
            query
        }, {
            headers
        });
        return response.data;
    } catch (error) {
        console.error('API 请求失败:', error);
        throw error;
    }
};

const getYoutubeVideoId = (url: string) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[2].length === 11) ? match[2] : null;
};

export default function Layout({ children, companyName }: LayoutProps): JSX.Element {
    const [partners, setPartners] = useState<Partner[]>([]);
    const [activeRegion, setActiveRegion] = useState<string>("");
    const [bannerImages, setBannerImages] = useState<string[]>([]);
    const [mobileContentImages, setMobileContentImages] = useState<string[]>([]);
    const [videoUrl, setVideoUrl] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                // 获取所有组列表
                const groupsResponse = await callApi('/group/', 'GET', {}, {});
                const mainGroup = groupsResponse.find((group: any) => group.name === GROUPS.MAIN);

                if (mainGroup) {
                    // 获取主区域组的详细数据
                    const groupDetail = await callApi(`/group/${mainGroup.id}`, 'GET', {}, {});

                    if (groupDetail.items && groupDetail.items.length > 0) {
                        // 按类型分类并仅获取最新的一个
                        const itemsByType: Record<string, any> = {};
                        groupDetail.items.forEach((item: { name: string; created_at: string }) => {
                            if (!itemsByType[item.name] ||
                                new Date(item.created_at) > new Date(itemsByType[item.name].created_at)) {
                                itemsByType[item.name] = item;
                            }
                        });

                        // 设置合作伙伴信息
                        if (itemsByType[MAIN_ITEMS.PARTNERS] && itemsByType[MAIN_ITEMS.PARTNERS].information?.partners) {
                            setPartners(itemsByType[MAIN_ITEMS.PARTNERS].information.partners);
                        }

                        // 设置横幅图片
                        if (itemsByType[MAIN_ITEMS.BANNER]) {
                            const bannerUrls = itemsByType[MAIN_ITEMS.BANNER].blobs?.map((blob: { url: string }) => blob.url) || [];
                            setBannerImages(bannerUrls);
                        }

                        // 设置移动内容图片
                        if (itemsByType[MAIN_ITEMS.MOBILE]) {
                            const mobileUrls = itemsByType[MAIN_ITEMS.MOBILE].blobs?.map((blob: { url: string }) => blob.url) || [];
                            setMobileContentImages(mobileUrls);
                        }

                        // 设置视频内容 URL
                        if (itemsByType[MAIN_ITEMS.VIDEO] && itemsByType[MAIN_ITEMS.VIDEO].information?.videoUrl) {
                            setVideoUrl(itemsByType[MAIN_ITEMS.VIDEO].information.videoUrl);
                        }
                    }
                } else {
                    console.error('未找到主区域组');
                    message.error('未找到主区域组');
                }
            } catch (error) {
                console.error('获取数据失败:', error);
                message.error('获取数据失败');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (partners.length > 0) {
            setActiveRegion(partners[0].category);
        }
    }, [partners]);

    return (
        <Spin spinning={loading} tip="載入中..." size="large">
            <div className="flex flex-col lg:flex-row min-h-screen w-full bg-white max-w-[1920px] mx-auto">
                {/* Mobile Header - only show on small screen */}
                <header className="fixed top-0 left-0 right-0 bg-white z-50 lg:hidden">
                    <div className="h-[80px] flex items-center justify-center border-b border-gray-200">
                        <div className="flex items-center space-x-2">
                            <img
                                src="/logo512.png"
                                alt={companyName}
                                width={40}
                                height={40}
                            />
                            <span className="text-[20px] font-semibold tracking-[0.3em] font-inter">
                                {companyName}
                            </span>
                        </div>
                    </div>
                </header>

                {/* Left content area - hidden on small screen and show on large screen */}
                <div className="hidden lg:block lg:w-[320px] border-r border-gray-200 py-10">
                    <div className="flex justify-center flex-col space-y-4">
                        <div className="flex justify-center items-center space-x-2 mb-8">
                            <img src="/logo512.png" alt={companyName} width={70} height={70} />
                            <span className="text-[32px] font-semibold leading-[38.73px] tracking-[0.3em] font-inter">{companyName}</span>
                        </div>

                        <nav className="flex flex-col space-y-9">
                            <a href="#" className="text-gray-700 hover:text-blue-600 text-center font-inter text-2xl tracking-[0.3em] leading-[29.05px]">
                                關於我們
                            </a>
                            <a href="#" className="text-gray-700 hover:text-blue-600 text-center font-inter text-2xl tracking-[0.3em] leading-[29.05px]">
                                最新消息
                            </a>
                            <a href="#" className="text-gray-700 hover:text-blue-600 text-center font-inter text-2xl tracking-[0.3em] leading-[29.05px]">
                                服務方案
                            </a>
                            <a href="#" className="text-gray-700 hover:text-blue-600 text-center font-inter text-2xl tracking-[0.3em] leading-[29.05px]">
                                加入我們
                            </a>
                        </nav>
                    </div>
                </div>

                {/* Middle content area */}
                <div className="w-full h-full lg:flex-1 min-w-0 lg:mt-[50px]">
                    {/* Carousel component */}
                    <div className="relative">
                        <Carousel
                            autoplay
                            className="!h-full lg:rounded-none lg:m-0 m-3 rounded-lg"
                            dots={{ className: 'dots-bottom' }}
                        >
                            {bannerImages.map((url, index) => (
                                <div key={index} className="h-full aspect-[1180/595] relative">
                                    <img
                                        src={url}
                                        alt={`banner${index + 1}`}
                                        className="lg:rounded-none rounded-lg object-cover w-full h-full"
                                    />
                                </div>
                            ))}
                        </Carousel>
                    </div>

                    {mobileContentImages.length > 0 && (
                        mobileContentImages.map((url, index) => (
                            <img
                                key={index}
                                src={url}
                                alt={`mobile content ${index + 1}`}
                                className="w-full h-full object-cover mt-[58px]"
                                width={1920}
                                height={1080}
                            />
                        ))
                    )}

                    {/* Render children */}
                    {children}
                </div>

                {/* Right content area */}
                <div className="w-full lg:w-[420px] border-t lg:border-t-0 lg:border-l border-gray-200 p-4">
                    <h2 className="text-[24px] lg:text-[24px] text-center font-normal lg:tracking-[0.3em] lg:leading-[29.05px] font-inter mb-4 text-[18px] font-lexend font-semibold leading-[28px] lg:font-normal">
                        合作廠商
                    </h2>

                    {/* Region classification */}
                    <Tabs
                        activeKey={activeRegion}
                        items={Array.from(new Set(partners.map(partner => partner.category))).map(category => ({
                            key: category,
                            label: category,
                        }))}
                        onChange={(key) => {
                            setActiveRegion(key);
                        }}
                        className="partner-tabs"
                    />

                    {/* Logo grid */}
                    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-2 gap-4">
                        {partners
                            .filter(partner => partner.category === activeRegion)
                            .map((partner) => (
                                <div
                                    key={partner.id}
                                    className="border border-gray-100 rounded-lg flex items-center justify-center h-20 sm:h-24"
                                >
                                    <a href={partner.url} target="_blank" rel="noopener noreferrer" className="relative w-full h-full">
                                        <img
                                            src={partner.logo}
                                            alt="合作廠商"
                                            className="object-contain w-full h-full"
                                        />
                                    </a>
                                </div>
                            ))}
                    </div>
                </div>
            </div>

            {/* Footer */}
            <footer className="bg-[#EAE6D9] p-6 mt-[20px]">
                <div className="text-center">
                    <div className="flex flex-col items-center">
                        <div className="flex justify-center items-center">
                            <img
                                src="/logo-icon.png"
                                alt="_logo.png"
                                className="h-[54px] w-[58px] mr-2"
                            />
                            <h2 className="text-[24px] font-bold">慰心科技</h2>
                        </div>
                        <p className="text-[16px]">全新親人AI還原服務，讓愛與回憶延續</p>
                    </div>
                    <div className="flex justify-center mt-4">
                        <a href="https://youtube.com/@healtechai" className="mr-2">
                            <button className="flex justify-center hover:opacity-75 active:opacity-50 transition-opacity duration-200 h-[40px] w-[40px] rounded-full items-center bg-white">
                                <img src="/yt.png" alt="yt.png" className="h-[25px]" />
                            </button>
                        </a>
                        <a href="https://www.tiktok.com/@healtech.ai" className="mr-2">
                            <button className="flex justify-center hover:opacity-75 active:opacity-50 transition-opacity duration-200 h-[40px] w-[40px] rounded-full items-center bg-white">
                                <img src="/tiktok.png" alt="tiktop.png" className="h-[25px]" />
                            </button>
                        </a>
                        <a href="https://www.instagram.com/healtech.ai" className="mr-2">
                            <button className="flex justify-center hover:opacity-75 active:opacity-50 transition-opacity duration-200 h-[40px] w-[40px] rounded-full items-center bg-white">
                                <img src="/Instagram.png" alt="instagram.png" className="h-[25px]" />
                            </button>
                        </a>
                        <a href="https://www.facebook.com/profile.php?id=61567308473649&mibextid=LQQJ4d" className="mr-2">
                            <button className="flex justify-center hover:opacity-75 active:opacity-50 transition-opacity duration-200 h-[40px] w-[40px] rounded-full items-center bg-white">
                                <img src="/Facebook.png" alt="facebook.png" className="h-[25px]" />
                            </button>
                        </a>
                    </div>
                </div>
                <hr className="my-[20px] border-t border-gray-300" />
                <p className="text-center text-sm">
                    ©2024 慰心科技. All rights reserved
                </p>
            </footer>
        </Spin>
    );
}
